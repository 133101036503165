import * as React from "react"
import PropTypes from "prop-types"
import "../styles/general-styles.css"

const Header = ({ siteTitle }) => <header></header>

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Pool Heating Advisory | Pool Heating`,
}

export default Header
